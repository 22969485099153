import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import {GatsbyImage} from 'gatsby-plugin-image'
import {P} from "./Typography"

const ImageSection = styled.div`
grid-column: 1 / span 6;
grid-row: 1 / span 1;
display: grid;
position: relative;
height: 100vw;
width: 100%;

:hover {
    cursor: pointer;
}

@media ${props => props.theme.breakpoints.desktop} {
    grid-column: 8 / span 5;
    grid-row: 1 / span ${props => props.rowSpan};
    height: 100%;
    width: 100%;

    :hover > p {
        opacity: 1;
        transition: opacity 0.3s;
    }
}
`

const AutoImage = styled.div`
position: absolute;

> div {
    border-radius: 10%;
}
`

const ShufflePrompt = styled(P)`
    text-transform: uppercase;
    position: sticky;
    right: 0;
    text-align: right;
    z-index: 999;
    font-weight: 450;
    top: 50vh;
    opacity: 0;
    transition: opacity 0.3s;
    height: 30px;
`


const RndmImgSection = ({children, rowSpan}) => {
    const data = useStaticQuery(graphql`
        query RndmImgSectionQuery {
            allSanityPost(sort: {fields: publishedAt, order: DESC}, skip: 0, limit: 5) {
                nodes {
                    mainImage {
                        asset {
                            gatsbyImageData(width: 800)
                        }
                    }
                }
            }
        }
    `)

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    
    function rndmPlacement() {
        var allPositions = []
        for (var i = 0; i < data.allSanityPost.nodes.length; i++) { 
            var width = randomIntFromInterval(10, 60);
            var top = randomIntFromInterval(0, 100-width);
            var left = randomIntFromInterval(0, 100-width);
    
            var posObject = {};
    
            posObject['width'] = width
            posObject['top'] = top
            posObject['left'] = left
    
            allPositions.push(posObject)
        }
    
        return allPositions
    }
    
    
    const [positions, setPositions] = useState(rndmPlacement)
    
    return (
        <ImageSection 
        onClick={() => {setPositions(rndmPlacement)}}
        rowSpan = {rowSpan}
        >
            <ShufflePrompt context="smalltext">Shuffle</ShufflePrompt>
            {data.allSanityPost.nodes.map((post, i) => {
                return (
                    <AutoImage key={i} style={{width: positions[i].width + "%", top: positions[i].top + "%", left: positions[i].left + "%"}}>
                        <GatsbyImage image={post.mainImage.asset.gatsbyImageData} alt=""/>
                    </AutoImage>
                )
            })}
        </ImageSection>
    )
}

export default RndmImgSection