import React from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import {H1} from "../components/Typography"
import styled from "styled-components"
import * as Sections from "../components/SectionElements"
import serializer from "../components/NonArticleSerializer"
import RndmImgSection from "../components/rndmImgSection"
import SEO from "../components/seo"

const BackDark = styled.span`
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    margin-right: 2px;
    margin-bottom: 2px;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.foreground};
`
const BackGray = styled.span`
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    margin-right: 2px;
    margin-bottom: 2px;
    color: ${props => props.theme.colors.foreground};
    background-color: ${props => props.theme.colors.gray2};
`
const BackLightGray = styled.span`
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    margin-right: 2px;
    margin-bottom: 2px;
    color: ${props => props.theme.colors.foreground};
    background-color: ${props => props.theme.colors.gray3};
`

const MemberPart = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-bottom: ${props => props.theme.spacings.xxLarge};
    align-items: baseline;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(6,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 2 / span 6;
    }
`

const PermMemberName = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: baseline;

    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    margin-bottom: ${props => props.theme.spacings.xxSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 6;
    }
`
const TempMemberName = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: baseline;

    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    margin-bottom: ${props => props.theme.spacings.xxSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 6;
    }
`
const PermMemberBio = styled.div`
    // padding-bottom: ${props => props.theme.spacings.large};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    grid-column: 1 / span 6;
    grid-row: 3 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 6;
    }
`

const ExtButton = styled.a`
    background-color: ${props => props.theme.colors.gray3};
    color: ${props => props.theme.colors.foreground};
    margin-right: 2px;
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;

    :hover {
        background-color: ${props => props.theme.colors.gray2};
        color: ${props => props.theme.colors.foreground};
    }

    @media ${props => props.theme.breakpoints.desktop} {
        box-shadow: none;
    }
`

const ThreeSectionChild = styled.div`
    grid-column: span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-bottom: ${props => props.theme.spacings.xxLarge};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(4,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: span 4;
    }
`

const SectionTitle = styled.div`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 12;
`

const TeamPage = ({data}) => {
    const edges = data.allSanityAuthor.edges
    const miscTexts = data.allSanityMiscText.nodes[0]

    const permanents = []
    const ponctuels = []
    const dividecats = () => {
        edges.map((edge, i) => {
            if (edge.node.status[0] === 'permanent') {
                permanents.push(edge)
            } else if (edge.node.status[0] === 'ponctuel') {
                ponctuels.push(edge)
            }
        })
    }
    dividecats()

    return (
        <Layout>
            <SEO 
            title="Équipe"
            />
            <SectionTitle>
                <BackDark>
                    &Eacute;quipe
                </BackDark>
            </SectionTitle>
            <RndmImgSection rowSpan="3"/>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={miscTexts._rawTeamintro}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={miscTexts._rawPermmembersintro}
                    serializers = {serializer}
                    />
                </Sections.Text>
                {permanents.map((edge, i) => {
                    return (
                        <MemberPart key={i}>
                            <PermMemberName>
                                <BackGray>{edge.node.firstname} {edge.node.surname}</BackGray>
                                <BackLightGray>{edge.node.role}</BackLightGray>
                                <BackLightGray>{edge.node.email}</BackLightGray>
                                {edge.node.linksArray.map((extlink, k) => {
                                    return (
                                        <ExtButton key={k} href={extlink.url} target='_blank' rel='noopener noreferrer'>{extlink.extLinkName}</ExtButton>
                                    )
                                })}
                            </PermMemberName>
                            <PermMemberBio>
                                <PortableText
                                blocks={edge.node._rawBio}
                                serializers = {serializer}
                                />
                            </PermMemberBio>
                        </MemberPart>
                    )
                })}
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={miscTexts._rawTempmembersintro}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.ThreeSection>
                {ponctuels.map((edge, i) => {
                    return (
                        <ThreeSectionChild key={i}>
                            <TempMemberName>
                                <BackGray>{edge.node.firstname} {edge.node.surname}</BackGray>
                                <BackLightGray context="smalltext">{edge.node.role}</BackLightGray>
                                {edge.node.linksArray.map((extlink, k) => {
                                    return (
                                        <ExtButton key={k} href={extlink.url} target='_blank' rel='noopener noreferrer'>{extlink.extLinkName}</ExtButton>
                                    )
                                })}
                            </TempMemberName>
                        </ThreeSectionChild>
                    )
                })}
            </Sections.ThreeSection>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={miscTexts._rawRecruit}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
        </Layout>
    )
}

export default TeamPage

export const pageQuery = graphql`
    query TeamQuery {
        allSanityAuthor(sort: {fields: [permOrder,surname], order: ASC}) {
            edges {
                node {
                    firstname
                    surname
                    role
                    status
                    id
                    email
                    _rawBio
                    linksArray {
                        url
                        extLinkName
                    }
                }
            }
        }
        allSanityMiscText {
            nodes {
                _rawRecruit
                _rawTeamintro
                _rawPermmembersintro
                _rawTempmembersintro
            }
        }
        allSanityPost(sort: {fields: publishedAt, order: DESC}, skip: 0, limit: 5) {
            nodes {
                mainImage {
                    asset {
                        gatsbyImageData(width: 800)
                    }
                }
            }
        }
    }
`